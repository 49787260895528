<template>
  <div class="page">

    <div class="title">
      <span>{{ item.title }}</span>
      <div class="download" @click="download" v-if="showDownBit">下载</div>
    </div>
    <div class="ct">
      <div class="info">
        <div class="info-item">来源：中思拓研究院</div>
        <div class="info-item">内容类型：{{ item.detailsName }}</div>
        <div class="info-item">
          发布时间：{{ formatDate(item.publishTime) }}
        </div>
      </div>

      <div :class="['abstract', isOpen ? '' : 'hide']" v-html="item.overview"></div>

      <div :class="['open', isOpen ? '' : 'hide']" @click="changeOpen">
        {{ open }}
      </div>
      <div class="page-box">
        <div class="wrapper">
          页码：
          <Input :value="pageNum" @on-blur="inputBlur($event)" style="width: 50px" />
          /{{ pageTotalNum }}
        </div>
        <div class="sliderWrapper">
          <span class="info">缩小</span>
          <Slider v-model="scaleTimes" :max="100" :step="1" @on-input="changeScaleTimes($event)"></Slider>
          <span class="info big">放大</span>
        </div>
        <!-- <Page :total="pageTotalNum*10" :current="pageNum" @on-change="inputBlur($event)" simple />      -->
      </div>
      <div class="pdf-box" :style="pdfStyle">
        <div class="btnBox">
          <div class="btn preBtn" :style="preBtnStyle" @click="prePage"></div>
          <div class="btn nextBtn" :style="nextBtnStyle" @click="nextPage"></div>
          <div class="backToMenu" @click="inputBlur2(3)">返回目录</div>
        </div>
        <pdf ref="pdf" :page="pageNum" :rotate="pageRotate" @progress="loadedRatio = $event"
          @page-loaded="pageLoaded($event)" @num-pages="pageTotalNum = $event" @error="pdfError($event)"
          @link-clicked="clickLink($event)" :src="src"></pdf>
      </div>
      <Progress :percent="(pageNum / pageTotalNum) * 100" v-show="pageTotalNum" :stroke-width="5" stroke-color="#2d8cf0"
        hide-info />
      <div class="btn-box">
        <Page :total="pageTotalNum * 10" :current="pageNum" @on-change="inputBlur2($event)" prev-text="上一页"
          next-text="下一页" />
        <div class="wrapper">
          <Input :value="pageNum" @on-blur="inputBlur($event)" style="width: 50px" />
          /{{ pageTotalNum }}
        </div>
      </div>

      <div class="pdf-info">
        <div class="pdf-info-item" :class="{ active: item.like }" @click="likeDetail">
          <div class="icon"></div>
          点赞：{{ info.likeCount }}
        </div>
        <div class="pdf-info-item star" :class="{ active: item.attention }" @click="attention">
          <div class="icon"></div>
          关注：{{ info.attentionCount }}
        </div>
        <div class="pdf-info-item">阅读：{{ info.readCount }}</div>
        <div class="pdf-info-item">下载量：{{ info.downloadCount }}</div>
      </div>

      <Form :model="formItem" label-position="top">
        <FormItem label="我要发言（500字以内）" class="top-item">
          <Input v-model="formItem.word" type="textarea" maxlength="500" :rows="6" />
        </FormItem>
      </Form>

      <div class="summit-btn" @click="summit">提交</div>

      <div class="message-box">
        <div class="message-box-title">留言录</div>
        <div class="item-list">
          <div class="item" v-for="(item, index) in message" :key="index">
            <div class="cover">
              <img :src="item.userLogo" alt="" width="100%" height="100%" />
            </div>
            <div class="item-info">
              <div class="top">
                <div class="name">用户：{{ item.userName }}</div>
                <div class="time">
                  {{ new Date(item.gmtUpdated).toLocaleString() }}
                </div>
              </div>
              <div class="des">{{ item.message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { formatDate } from "@/utils";
import util from "@/utils/utils2";

import {
  deep_detail,
  deep_show_report,
  deep_down_report,
  deep_message,
  deep_like,
  deep_save_message,
  down_deep,
  read_deep,
  deep_add_like,
  close_deep_like,
  deep_attention,
  close_deep_attention,
  deep_page,
} from "@/api/reSearch";
import axios from 'axios';
export default {
  components: {
    pdf,
  },
  data() {
    return {
      first: true,
      showDownBit: false,// 显示下载按钮
      viewPrim: false,// 查看权限
      id: 0,
      item: {},
      info: {},
      formItem: {},
      message: [],

      pageNum: 1,
      pageTotalNum: 1,
      pageRotate: 0,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,
      // src : "http://image.cache.timepack.cn/nodejs.pdf",
      src: "",
      scaleTimes: 0,
      pdfStyle: {
        padding: `1px 300px 30px`,
      },
      preBtnStyle: {
        "margin-left": "10px",
      },
      nextBtnStyle: {
        "margin-right": "10px",
      },
      //简介开关
      isOpen: false,
    };
  },
  computed: {
    open() {
      return this.isOpen ? "收起" : "展开";
    },
  },
  mounted() {
    let params = this.$route.query;
    this.id = params.id;
    this.getDetail();
    this.getMessage();
    this.getLike();
    this.changeScaleTimes(50);
    window.addEventListener("keyup", this.clickKey);

    // setTimeout(() => {
    //    this.pageTotalNum = 10
    // }, 2000);

  },
  methods: {
    formatDate(data) {
      return formatDate(data);
    },
    async test() {
      this.getShowReport()

    },
    async downloadPdf() {
      let params = {
        id: this.id,
      }
      const response = await axios.post('/service-insight/deep_record/down-report', params, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      let name = this.item.detailsName + '.pdf'
      a.download = name
      a.click()
      window.URL.revokeObjectURL(url)


    },
    getDetail() {
      this.getShowReport()
      deep_detail({
        id: this.id,
      }).then((data) => {
        if (data.overview) {
          data.overview = data.overview
            .split("☆")
            .map((txt) => {
              txt = txt.trim();
              return txt ? `<p>☆${txt}</p>` : "";
            })
            .join("");
        }

        this.item = data;

        // let url = data.url.replace("www.zhongsituo.com", "zhongsituo.com");
        // this.src = data.url;
        // let task = pdf.createLoadingTask(this.src);
        // this.src = "http://image.cache.timepack.cn/nodejs.pdf"
        data.permList.map(k => {

          if (k.title == '查看' && k.checked) {
            this.viewPrim = true;
          }
          if (k.title == '下载' && k.checked) {
            this.showDownBit = true;
          }

        })
        // this.src = url;
        // let task = pdf.createLoadingTask(this.src);
        // task.promise.then((pdf) => {
        //   this.numPages = pdf.numPages;

        // });

        // //这里写对应的逻辑
        // // this.showDownBit = true;


        read_deep({
          id: this.id,
        });
      });
    },
    async getShowReport() {//获取是否展示报告
      //  deep_show_report
      let params = {
        id: this.id,

      }
      // let data = await deep_show_report(params);
      // console.log('data', data)
      try {
        const response = await axios.post('/service-insight/deep_record/show-report', params, {
          responseType: 'blob'
        });
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = URL.createObjectURL(blob)
        this.src = url;
        let task = pdf.createLoadingTask(this.src);
        task.promise.then((pdf) => {
          this.numPages = pdf.numPages;
        });
          if (!this.viewPrim) {//如果没有查看权限
           setTimeout(() => {
           this.pageTotalNum = 5
        }, 1000);
        }
        // console.log('response111', response)
        // return response;
      } catch (error) {
     
        throw error;
      }
      // let data = JSON.stringify({
      //    "id": 131
      // });
      // let data = {
      //         id: 131,
      //       }
      // let config = {
      //    method: 'post',
      //    url: 'https://api.zstnb.cn:18081/service-insight/deep_record/show-report',
      //    data : data
      // };
      // axios(config)
      // .then(function (response) {
      //    console.log(JSON.stringify(response.data));
      // })
      // .catch(function (error) {
      //    console.log(error);
      // });


    },
    inputBlur(e) {
      let val = e.target.value;
      if (val > this.pageTotalNum) {
        val = this.pageTotalNum;
      } else if (val < 1) {
        val = 1;
      }
      this.pageNum = +val;
    },
    inputBlur2(e) {
      this.pageNum = e;
    },
    // 上一页函数，
    prePage() {
      var page = this.pageNum;
      page = page > 1 ? page - 1 : 1;
      this.pageNum = page;
    },
    // 下一页函数
    nextPage() {
      if (this.first) {
        this.first = false;
      }
      var page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : this.pageTotalNum;
      this.pageNum = page;
    },
    // 页面顺时针翻转90度。
    clock() {
      this.pageRotate += 90;
    },
    // 页面逆时针翻转90度。
    counterClock() {
      this.pageRotate -= 90;
    },
    // 页面加载回调函数，其中e为当前页数
    pageLoaded(e) {
      this.curPageNum = e;
    },
    // 其他的一些回调函数。
    pdfError(error) {
    },
    getMessage() {
      deep_message({
        reportId: this.id,
        size: 10,
        current: 1,
      }).then((data) => {
        this.message = data.records;
      });
    },
    getLike() {
      deep_like({
        id: this.id,
      }).then((data) => {
        this.info = data;
      });
    },
    likeDetail() {
      if (this.item.like) {
        close_deep_like({
          id: this.id,
        }).then((data) => {
          this.item.like = 0;
          this.info.likeCount -= 1;
        });
      } else {
        deep_add_like({
          id: this.id,
        }).then((data) => {
          this.item.like = 1;
          this.info.likeCount += 1;
        });
      }
    },
    attention() {
      if (this.item.attention) {
        close_deep_attention({
          id: this.id,
        }).then((data) => {
          this.item.attention = 0;
          this.info.attentionCount -= 1;
          this.$msg.error({ type: "remind", code: 4035 });
        });
      } else {
        deep_attention({
          id: this.id,
        }).then((data) => {
          this.item.attention = 1;
          this.info.attentionCount += 1;
          this.$msg.error({ type: "remind", code: 4036 });
        });
      }
    },
    download() {
      this.$btnlog(1046);
      let pageId = this.$route.query.pageId
      let pageName = this.$route.query.pageName
      this.$btnSysLog(1, 2, { pageId: pageId, pageName: pageName })
      down_deep({
        id: this.id,
      });
      this.downloadPdf();
      // window.open(this.item.url);
    },
    summit() {
      deep_save_message({
        // id : this.id,
        message: this.formItem.word,
        reportId: this.id,
        userId: this.$store.state.userId,
        userName: this.$store.state.userId,
      }).then((data) => {
        this.$msg.error({ type: "remind", code: 4037 });

        this.formItem.word = "";
        this.getMessage();
      });
    },
    clickLink(e) {
      this.pageNum = e;
    },
    //放大或缩小pdf
    changeScaleTimes(e) {
      this.scaleTimes = e;
      if (e > 100) {
        // this.$Message.warning("已经放到最大了");
        this.$msg.error({ text: "已经放到最大了" });
      } else if (e < 0) {
        // this.$Message.warning("已经缩到最小了");
        this.$msg.error({ text: "已经缩到最小了" });
      }
      this.pdfStyle = {
        padding: `50px ${500 - this.scaleTimes * 4}px 50px`,
      };
      this.preBtnStyle = {
        "margin-left": `${500 - this.scaleTimes * 4 - 80}px`,
      };
      this.nextBtnStyle = {
        "margin-right": `${500 - this.scaleTimes * 4 - 80}px`,
      };
    },
    changeOpen() {
      //改变详情的大小
      this.isOpen = !this.isOpen;
    },
    clickKey(e) {
      if (e.keyCode === 37) {
        this.prePage();
      } else if (e.keyCode === 39) {
        this.nextPage();
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.page {
  min-height: 100%;
  min-width: 1200px;
  padding: 20px;
  overflow: hidden;

  .title {
    @include flex;
    color: #333;
    font-size: 24px;
    font-weight: bold;

    .download {
      @include flex;
      width: 106px;
      height: 30px;
      color: #fff;
      margin-left: auto;
      font-size: 14px;
      font-weight: normal;
      border-radius: 5px;
      background-color: #00b6ff;
      cursor: pointer;
    }
  }

  .ct {
    margin-top: 20px;
    border-top: 1px solid #d6d7d9;
    position: relative;
  }

  .info {
    @include flex;
    justify-content: flex-start;
    margin: 20px 0 30px;

    &-item {
      margin-right: 100px;
    }
  }

  .abstract {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 14px 5px 14px 24px;
    margin-bottom: 0px;
    background-color: #f5f5f5;
    transition: all 0.5s;

    /deep/ p {
      margin-bottom: 10px;
      text-indent: 2em;
      display: block;
      width: 100%;
    }

    border: 2px solid #eee;

    &.hide {
      height: 90px;
      overflow: hidden;
      border-color: transparent;
    }
  }

  .open {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    margin-bottom: 30px;
    cursor: pointer;
    border: 2px solid #eee;
    border-top: none;
    text-align: center;
    width: 50px;
    padding: 3px;
    transition: all 0.5s;
    background-color: #f5f5f5;
    font-size: 12px;

    &.hide {
      border: 2px solid #eee;
    }
  }

  .page-box {
    margin-bottom: 10px;
    position: relative;

    .sliderWrapper {
      width: 200px;
      position: absolute;
      left: 225px;
      top: -3px;

      .info {
        position: absolute;
        top: -13px;
        left: -37px;
        width: 30px;

        &.big {
          left: 215px;
        }
      }
    }
  }

  .pdf-box {
    // @include flex;
    position: relative;

    // height: 1200px;
    margin: 0 auto;
    background: #f5f5f5;
    transition: padding 0.5s;

    .btnBox {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: 0;
      width: 100%;
      height: 100%;
      padding: 50px 0;

      .btn {
        width: 80px;
        height: 100%;
        text-align: center;
        background-color: transparent;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        transition: all 0.5s;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;

        &.preBtn {
          float: left;
          margin-left: 10px;
          background-image: url("~@/assets/image/preBtn.png");
        }

        &.nextBtn {
          float: right;
          margin-right: 10px;
          background-image: url("~@/assets/image/nextBtn.png");
        }

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);

          &.preBtn {
            background-image: url("~@/assets/image/preBtn_active.png");
          }

          &.nextBtn {
            background-image: url("~@/assets/image/nextBtn_active.png");
          }
        }
      }

      .backToMenu {
        color: #085c9a;
        font-size: 18px;
        position: absolute;
        top: 10px;
        left: 10px;
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.1);
        }
      }
    }
  }

  .btn-box {
    @include flex;
    margin-top: 10px;

    .wrapper {
      position: relative;
      left: 20px;
    }
  }

  .pdf-info {
    @include flex(flex-start);
    margin: 20px 0 30px;

    &-item {
      @include flex;
      margin-right: 60px;
      line-height: normal;

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      &.active {
        color: #00b6ff;
      }

      &:nth-child(1) {
        cursor: pointer;

        .icon {
          @include bgSrc("research/like.png");
        }
      }

      &:nth-child(2) {
        cursor: pointer;

        .icon {
          @include bgSrc("research/star.png");
        }
      }
    }
  }

  .summit-btn {
    @include flex;
    width: 114px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    background-color: #00b6ff;
    cursor: pointer;
  }

  .message-box {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #d6d7d9;

    &-title {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
    }

    .item-list {
      .item {
        @include flex(flex-start);
        margin-bottom: 20px;

        .cover {
          width: 60px;
          height: 60px;
          margin-right: 10px;
          border-radius: 50px;
          background-color: #eee;
        }

        &-info {
          .top {
            @include flex(flex-start);
            margin-bottom: 10px;

            .name {
              margin-right: 30px;
              font-size: 16px;
              font-weight: bold;
            }

            .time {}
          }

          .des {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>